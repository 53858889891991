<template>
  <div :style="{ 'background-image': `url(${src})`, width, height }">
    <slot v-bind="{ width, height }"></slot>
    <img ref="imageElement" v-bind="{ src }" style="opacity: 0 !important" />
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'

defineProps<{
  src: string
  height?: string
  width?: string
}>()

const imageElement = ref<HTMLImageElement | null>(null)
</script>
