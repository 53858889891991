<template>
  <form class="grow-1 col chat-p" @submit.prevent="send">
    <div class="chat-form">
      <span class="field">
        <label for="name">{{ t('fields.name') }} *:</label>
        <VInput v-model="v$.name.$model"></VInput>
        <VErrors :model="v$.name.$errors"></VErrors>
      </span>

      <template v-if="hasExtras">
        <div
          class="field extra"
          v-for="(field, fIndex) of api.client?.extras?.fields"
          :key="`customfield-${fIndex}`"
        >
          <label>{{ field.bindings.label }}</label>

          <template v-if="field.type === 'maska-input'">
            <VInput
              v-model="v$_customDataModels[field.bindings.model].$model"
              v-maska
              :data-maska="field.bindings.mask"
            ></VInput>
          </template>

          <VErrors :model="v$_customDataModels[field.bindings.model].$errors"></VErrors>
        </div>
      </template>

      <span class="field phone">
        <label for="name">{{ t('fields.phone') }} *:</label>
        <VInputPhone v-model="v$.uid.$model"></VInputPhone>
        <VErrors :model="v$.uid.$errors"></VErrors>
      </span>
    </div>

    <span class="row justify-end mt-auto">
      <VButton type="submit" :label="t('actions.send')" :loading="loading"></VButton>
    </span>
  </form>
</template>

<script setup lang="ts">
import { useChatWidgetStore } from '@/stores/chatWidget'
import { useContactsStore } from '@/stores/contacts'
import { useChatsStore } from '@/stores/chats'
import { useLocalStorage } from '@/composables/storage'
import { useApi } from '@contactoneteam/c1webapi'
import { storeToRefs } from 'pinia'
import { reactive, ref, onMounted } from 'vue'
import { vMaska } from 'maska/vue'

import VErrors from './form/VErrors.vue'
import VInputPhone from './form/VInputPhone.vue'
import VButton from '@/components/form/VButton.vue'
import VInput from '@/components/form/VInput.vue'
import { computed } from 'vue'
import { useI18n } from '@/composables/i18n'
import { onlyDigits } from '@/composables/helpers'
import useVuelidate from '@vuelidate/core'
import { numbersNotAllowed, required } from '@/composables/rules'

const { t } = useI18n()
const api = useApi()
const chatWidgetStore = useChatWidgetStore()
const { state } = storeToRefs(chatWidgetStore)
const contactStore = useContactsStore()
const chatsStore = useChatsStore()

const loading = ref(false)

type User = {
  uid: string
  name: string
}
const user: User = reactive({
  name: '',
  uid: ''
})

const rules = computed(() => {
  const nameRules = api.client?.extras?.overrides?.name?.rules || {}
  const uidRules = api.client?.extras?.overrides?.uid?.rules || {}

  return {
    name: {
      required,
      numbersNotAllowed,
      ...nameRules
    },
    uid: {
      required,
      ...uidRules
    }
  }
})

const v$ = useVuelidate(rules, user)

const hasExtras = computed(() => {
  return api.client?.extras?.fields && api.client.extras.fields.length
})

const customDataModels = ref<Record<string, string>>(
  Object.fromEntries(api.client?.extras?.fields?.map((field) => [field.bindings.model, '']) || [])
)

const customDataModelRules = computed(() => {
  const data = api.client?.extras?.fields || []

  return Object.fromEntries(
    data
      .filter((d) => d.bindings.rules !== undefined)
      .map((field) => [field.bindings.model, field.bindings.rules])
  )
})
const v$_customDataModels = useVuelidate(customDataModelRules, customDataModels)

const contactSession = useLocalStorage('co')
const chatSession = useLocalStorage('ch')

onMounted(async () => {
  if (!api.client) throw new Error('no client loaded')

  loading.value = true

  await v$.value.$validate()
  await v$_customDataModels.value.$validate()

  try {
    if (contactSession.value?.length) {
      const [name, uid, id] = contactSession.value.split(':')

      user.name = name

      await contactStore.createContact({
        name,
        uid,
        contactId: id,
        channelId: api.client.channelId,
        photoUrl: null
      })

      if (chatSession.value?.length) {
        chatsStore.chatId = chatSession.value

        try {
          await chatsStore.getChat()
          await chatsStore.getChatMessages()
        } catch (error: any) {
          if (hasExtras.value) {
            return
          }

          await chatsStore.createChat()
        }
      } else {
        if (hasExtras.value) {
          return
        }

        await chatsStore.createChat()
      }

      chatSession.value = chatsStore.chat?.id || ''
      state.value.meta.chat.state = 'chat'
    }
  } catch (error: any) {
    console.error(`cant resume a previous session`, error)
  } finally {
    loading.value = false
  }
})

function setSession() {
  contactSession.value = `${user.name || 'VC'}:${contactStore.contact?.uid}:${
    contactStore.contact?.id
  }`
  chatSession.value = chatsStore.chatId || ''
}

async function send() {
  if (loading.value) return

  await v$.value.$validate()
  await v$_customDataModels.value.$validate()

  if (v$.value.$error || v$_customDataModels.value.$error) {
    return
  }

  loading.value = true

  if (!api.client) throw new Error('no client loaded')

  let uid = user.uid || crypto.randomUUID().toString()

  // if (api.client.shortGuid) {
  //   const [first] = uid.split('-')
  //   uid = first
  // }

  try {
    await contactStore.createContact({
      ...user,
      channelId: api.client.channelId,
      uid
    })

    if (api.client?.extras?.fields.length) {
      const botCustomData = api.client.extras.fields.map((field) => ({
        type: field.bindings.customData.type,
        label: field.bindings.customData.label,
        value: onlyDigits(customDataModels.value[field.bindings.model])
      }))

      await chatsStore.createChat({
        botCustomData
      })
    } else {
      await chatsStore.createChat()
    }

    state.value.meta.chat.state = 'chat'

    setSession()
  } catch (error) {
    console.error(error)
  } finally {
    loading.value = false
  }
}
</script>
