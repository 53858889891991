<template>
  <img
    class="grayscale-action cursor-pointer transition-all"
    :style="{ maxHeight }"
    :src="C1Logo"
    @click="seeUs"
  />
</template>

<script setup lang="ts">
import C1Logo from '@/assets/image/c1.png'

withDefaults(
  defineProps<{
    maxHeight?: string
  }>(),
  {
    maxHeight: '44px'
  }
)

function seeUs() {
  window.open('https://contactone.com.br/', '_blank')?.focus()
}
</script>
