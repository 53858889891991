import { acceptHMRUpdate, defineStore } from 'pinia'
import { reactive } from 'vue'

type NoticeState = 'none' | 'end' | 'peek'
type State = {
  view: 'button' | 'chat'

  meta: {
    button: boolean
    title: string | null
    chat: {
      state: 'form' | 'chat' | 'end'
    }
    notice: {
      state: NoticeState
      active: boolean
    }
  }
}

export const useChatWidgetStore = defineStore('chat-widget', () => {
  const state: State = reactive({
    view: 'button',

    meta: {
      button: false,
      title: 'Identificação',
      chat: {
        state: 'form'
      },
      notice: {
        active: false,
        state: 'none'
      }
    }
  })

  function clearNotice() {
    state.meta.notice = {
      active: false,
      state: 'none'
    }
  }

  function setNotice(type: NoticeState, active = true) {
    state.meta.notice = {
      active,
      state: type
    }
  }

  function toggleButtonState() {
    state.meta.button = !state.meta.button
  }

  function show() {
    state.meta.button = true
  }

  function hide() {
    state.meta.button = false
  }

  return { state, clearNotice, setNotice, toggleButtonState, show, hide }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useChatWidgetStore, import.meta.hot))
}
