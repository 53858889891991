import { type Api } from '@contactoneteam/c1webapi'

export type CreateContactPayload = {
  channelId: string
  name: string
  uid?: string
  contactId?: string
  photoUrl?: string | null
}

export type LoginContactPayload = {
  name?: string
  uid?: string
  contactId?: string
  photoUrl?: string | null
  channelId: string
}

type LoginContactRequest = {
  data: LoginContactPayload
}

type LoginContactResponse = {
  contactId: string
}

type CheckTokenRequest = null
type CheckTokenResponse = null

export const RefreshToken: Api<CheckTokenRequest, CheckTokenResponse> = {
  url: 'contacts/token/refresh',
  method: 'post',
  headers: {}
}

export const LoginContact: Api<LoginContactRequest, LoginContactResponse> = {
  method: 'post',
  url: 'contacts/login',
  headers: {}
}
