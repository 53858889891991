<template>
  <form class="chat-input" @click.stop="routeFocus" @submit.prevent="send">
    <input
      ref="uploadElement"
      type="file"
      style="display: none; opacity: 0; position: absolute"
      :accept="acceptTypes()"
      :id="uploaderId"
      :max="5"
      :maxlength="5"
      @change="onChange"
    />

    <div class="input-wrapper grow-1 row">
      <VTextarea
        @keyup.prevent="onInputKeyup"
        v-model="messageText"
        :placeholder="`${t('actions.type')}...`"
        ref="textAreaComponent"
        type="text"
        autogrow
        :disabled="ended || loading || messages.length === 0"
      />
      <div class="actions" style="margin-left: 0.25em" @click.stop="routeFocus">
        <span class="items">
          <Transition name="slide-fade">
            <VButton
              v-bind="{ loading }"
              v-if="!messageText.length"
              class="button-round w-fit send-attachments button-text"
              @click.stop="requestAttachments"
              type="button"
            >
              <template v-slot="{ loading: bLoading }">
                <ClipIcon v-if="!bLoading" class="icon"></ClipIcon>
              </template>
            </VButton>
          </Transition>
          <!-- <VButton
            class="button-round w-fit emojis button-text"
            @click.stop="showEmojis"
            type="button"
          >
            <template v-slot="{ loading: bLoading }">
              <SmileIcon v-if="!bLoading" class="icon"></SmileIcon>
            </template>
          </VButton> -->
          <Transition name="slide-fade">
            <VButton
              class="button-round w-fit send button-text button-primary"
              v-if="messageText.length"
              type="submit"
            >
              <template v-slot="{ loading: bLoading }">
                <SendIcon v-if="!bLoading" class="icon text-primary"></SendIcon>
              </template>
            </VButton>
          </Transition>
        </span>
      </div>
    </div>
  </form>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useSendMessage } from '@/composables/messages'
import { useChatsStore } from '@/stores/chats'
import { useIllegalFiles } from './files'
import { useI18n } from '@/composables/i18n'

import SendIcon from './svg/SendIcon.svg'
import ClipIcon from './svg/ClipIcon.svg'
// import SmileIcon from './svg/SmileIcon.svg'
import VButton from './form/VButton.vue'
import VTextarea from './form/VTextarea.vue'
import { type MessageCustomData } from '@/api/chats'

const loading = ref(false)
const messageText = ref('')
const textAreaComponent = ref<InstanceType<typeof VTextarea>>()
const { sendMessage } = useSendMessage()
const chatStore = useChatsStore()
const { t } = useI18n()
const { ended, temporaryAttachments, messages } = storeToRefs(chatStore)

const uploaderId = 'headless_upload'
const uploadElement = ref<HTMLInputElement>()

function acceptTypes() {
  return ['.csv', '.txt', '.xls', '.docx', 'image/*', 'video/*', 'audio/*'].join(',')
}

function onChange() {
  if (!uploadElement.value) throw new Error('cant run change without an upload element')

  temporaryAttachments.value.items.push(
    ...Array.from(uploadElement.value.files || []).map((v) => {
      return {
        file: v,
        name: v.name,
        type: v.type
      }
    })
  )

  temporaryAttachments.value.visible = true
}

function routeFocus() {
  textAreaComponent.value?.inputEl?.focus()
}

const MAX_ATTACHMENTS = 5
function requestAttachments() {
  if (temporaryAttachments.value.items.length === MAX_ATTACHMENTS) return

  uploadElement.value?.click()
}

function onInputKeyup(evt: KeyboardEvent) {
  if (evt.code.toLowerCase().includes('enter')) {
    if (!evt.defaultPrevented) evt.preventDefault()

    send()
  }
}

const isIllegal = useIllegalFiles()

async function send() {
  loading.value = true

  let customData: MessageCustomData | null = null

  if (temporaryAttachments.value.items.length) {
    try {
      const { data } = await chatStore.uploadStorage(
        temporaryAttachments.value.items.filter((local) => !isIllegal.value(local.type))
      )

      customData = {
        attachments: data.contents.map((e) => {
          const name = e.url.split('/').pop()

          return {
            contentName: name,
            contentType: e.type,
            contentUrl: e.url
          }
        })
      }

      temporaryAttachments.value.items.splice(0)
      temporaryAttachments.value.visible = false
    } catch (error: any) {
      console.error(error)
    }
  }

  try {
    sendMessage({
      customData,
      content: {
        text: messageText.value.trim(),
        type: 'text/plain',
        url: null
      }
    })

    messageText.value = ''
    textAreaComponent.value?.handleAutogrow()
  } catch (error: any) {
    console.error(error)
  } finally {
    loading.value = false
  }
}
</script>
