<template>
  <div
    class="messages-scrollspy grow-1 chat-p relative"
    ref="messagesEl"
    id="messages-scrollspy"
    :style="{
      'padding-bottom': `${temporaryAttachments.visible === true ? '0' : '4'}px !important`
    }"
  >
    <ChatQueueNotice v-if="isQueue && !isBot && !chat?.answeredAt?.length"></ChatQueueNotice>
    <ChatMessage
      v-for="[message, index] of getMessages"
      :key="message.id"
      v-bind="{ messageId: message.id, index }"
    ></ChatMessage>
    <ChatMessageTyping v-if="isTyping && false"></ChatMessageTyping>
    <ChatAttachmentsDock :style="{ bottom: `-${messagesScrollHeight}px` }"></ChatAttachmentsDock>
    <VTextCredits
      v-if="!temporaryAttachments.visible"
      max-height="32px"
      style="margin-top: auto; font-size: 12px; padding-bottom: 0"
    />
  </div>
</template>

<script setup lang="ts">
import { type ChatMessage as TChatMessage } from '@/api/chats'
import ChatMessage from './ChatMessage.vue'
import ChatMessageTyping from './ChatMessageTyping.vue'
import ChatQueueNotice from './ChatQueueNotice.vue'
import ChatAttachmentsDock from './ChatAttachmentsDock.vue'
import VTextCredits from './VTextCredits.vue'

import { useChatsStore } from '@/stores/chats'
import { useContactsStore } from '@/stores/contacts'
import { useSendMessage } from '@/composables/messages'
import { storeToRefs } from 'pinia'
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'

const contactStore = useContactsStore()
const { contact } = storeToRefs(contactStore)
const chatsStore = useChatsStore()
const { scrollToBottom } = useSendMessage()
const { messages, isQueue, isEnded, chat, temporaryAttachments, isBot } = storeToRefs(chatsStore)

const messagesEl = ref<HTMLDivElement | null>(null)
const messagesScrollHeight = ref(0)

const interval = setInterval(() => {
  messagesScrollHeight.value = messagesEl.value?.scrollTop || 0
})

const getMessages = computed<[TChatMessage, number][]>(() => {
  return messages.value.map((message, index) => [message, index])
})

const isTyping = computed(() => {
  // we should make this part of the client definition so it becomes opt-in
  return (
    isBot.value &&
    !isEnded.value &&
    messages.value[messages.value.length - 1]?.from.id === contact.value?.id
  )
})

let intersectionObserver: IntersectionObserver
let mutationObserver: MutationObserver

function acceptableNode(n: Node) {
  if (n instanceof Element) {
    return n.getAttribute('spy-useable') === 'true'
  }

  // vue signals
  return false
}

function watchElements(ns: Node[]) {
  for (const node of ns) {
    if (node instanceof Element) {
      intersectionObserver.observe(node)
    } else {
      throw new Error('unexpected element received')
    }
  }
}

function unwatchElements(ns: Node[]) {
  try {
    if (ns instanceof Element) {
      intersectionObserver.unobserve(ns)
    }
  } catch (error: any) {
    // ignore
  }
}

const onMutation: MutationCallback = (records) => {
  for (const node of records) {
    let toAdd = Array.from(node.addedNodes).filter(acceptableNode)
    let toRemove = Array.from(node.removedNodes)

    watchElements(toAdd)
    unwatchElements(toRemove)
  }
}

const messageIdsToRead: string[] = []

function clearMessageIds(items: string[]) {
  for (const id of items) {
    messageIdsToRead.splice(messageIdsToRead.indexOf(id), 1)
  }
}

const readerState = {
  busy: false
}
let readerInterval = setInterval(async () => {
  if (messageIdsToRead.length && !readerState.busy && !chatsStore.isEnded) {
    let theseMessageIds = messageIdsToRead

    readerState.busy = true

    try {
      await chatsStore.readMessage(theseMessageIds)
      clearMessageIds(theseMessageIds)
    } catch (error: any) {
      messageIdsToRead.splice(0)

      console.error('cant read messages', error.message)
    } finally {
      readerState.busy = false
    }
  }
}, 2 * 1000)

const onIntersection: IntersectionObserverCallback = (records) => {
  // here we assume it is a readable
  for (const intersection of records) {
    const id = intersection.target.getAttribute('data-id')

    if (!id) {
      console.warn('Intersected an idless reader-labeled element')
    } else {
      if (!messageIdsToRead.includes(id)) {
        messageIdsToRead.push(id)
      }
    }
  }
}

onMounted(() => {
  if (!messagesEl.value) throw new ReferenceError('no wrapper')

  intersectionObserver = new IntersectionObserver(onIntersection, {
    root: messagesEl.value,
    rootMargin: '10px',
    threshold: 0.65
  })
  mutationObserver = new MutationObserver(onMutation)

  mutationObserver.observe(messagesEl.value, {
    childList: true
  })
})

const lastMessageId = computed(() => {
  return messages.value.length ? messages.value[messages.value.length - 1].id : null
})

watch(lastMessageId, () => {
  setTimeout(() => {
    scrollToBottom()
  }, 100)
})

onUnmounted(() => {
  clearInterval(interval)
  mutationObserver?.disconnect()
  intersectionObserver?.disconnect()

  clearInterval(readerInterval)
})
</script>
