<template>
  <div class="v-chat-view col grow-1">
    <ChatNotice>
      <template v-if="type === 'end'">
        <section class="end-notice inner-notice grow-1 col justify-center items-center">
          <span class="col grow-1 justify-center items-center">
            <h4 class="text-bold title">{{ t('messages.ended.title') }}</h4>
            <span>{{ t('messages.ended.description') }}</span>
          </span>
          <span class="row justify-center items-center" style="gap: 4px; margin-bottom: 25px">
            <VButton :label="t('actions.viewChat')" class="w-fit" @click="peek" />
            <VButton
              :loading="loadingStates.create"
              :label="t('actions.newChat')"
              class="w-fit button-success"
              @click="createChat"
            />
          </span>
        </section>
        <VTextCredits></VTextCredits>
      </template>
    </ChatNotice>
    <ChatMessages></ChatMessages>
    <ChatInput></ChatInput>
  </div>
</template>

<script setup lang="ts">
import VButton from './form/VButton.vue'
import VTextCredits from './VTextCredits.vue'
import ChatMessages from './ChatMessages.vue'
import ChatInput from './ChatInput.vue'
import ChatNotice from './ChatNotice.vue'
import { useChatsStore } from '@/stores/chats'
import { useChatWidgetStore } from '@/stores/chatWidget'
import { useContactsStore } from '@/stores/contacts'
import { useLocalStorage } from '@/composables/storage'
import { computed, onBeforeUnmount, onMounted, reactive, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { useI18n } from '@/composables/i18n'

const { t } = useI18n()
const widgetStore = useChatWidgetStore()
const chatSession = useLocalStorage('ch')
const chatsStore = useChatsStore()
const contactStore = useContactsStore()
const { ended } = storeToRefs(chatsStore)

const { state } = storeToRefs(widgetStore)
const type = computed(() => state.value.meta.notice.state)

onMounted(() => {
  chatsStore.startUpdates()
  contactStore.startRefreshes()

  setTimeout(() => {
    if (ended.value) {
      widgetStore.setNotice('end')
    }
  }, 3000)
})

onBeforeUnmount(() => {
  chatsStore.stopUpdates()
})

const loadingStates = reactive({
  create: false
})
async function createChat() {
  if (loadingStates.create) return

  loadingStates.create = true

  try {
    await chatsStore.createChat()
    widgetStore.setNotice('none', false)
  } catch (error: any) {
    console.error('Cant create chat', error)
  } finally {
    loadingStates.create = false
  }
}

function peek() {
  widgetStore.setNotice('peek', false)
}

watch(ended, (hasEnded) => {
  if (hasEnded) {
    widgetStore.setNotice('end')

    chatSession.value = ''
  }
})
</script>
