<template>
  <input v-bind="{ type }" class="v-input" v-model="_text" @keyup="formatText" />
</template>

<script setup lang="ts">
import { userInfo } from 'os'
import { onMounted } from 'vue'
import { ref } from 'vue'
import { type InputTypeHTMLAttribute } from 'vue'

const props = withDefaults(
  defineProps<{
    modelValue: string
    type?: InputTypeHTMLAttribute
  }>(),
  {
    type: 'text'
  }
)
const emit = defineEmits<{
  (e: 'update:modelValue', v: string): void
  (e: 'change', to: string, from: string): void
}>()

const _text = ref('')

function formatText() {
  if (_text.value.length) {
    var inputValue = _text.value

    var numericValue = inputValue.replace(/\D/g, '')

    var maskFormat = '(##) ####-####'

    if (numericValue.length === 11) {
      maskFormat = '(##) #####-####'
    }

    var maskedValue = ''
    var valueIndex = 0
    for (var i = 0; i < maskFormat.length; i++) {
      if (maskFormat[i] === '#') {
        maskedValue += numericValue[valueIndex] || ''
        valueIndex++
      } else {
        maskedValue += maskFormat[i]
      }
    }

    _text.value = maskedValue
  } else {
    _text.value = ''
  }

  const clear = (_text.value.match(/\d+/g) || '').toString().split(',').join('')
  emit('update:modelValue', clear)
  emit('change', clear, '')
}

onMounted(() => {
  _text.value = props.modelValue
  formatText()
})
</script>
