<template>
  <div
    class="file-chip row items-center"
    style="gap: 4px"
    :class="{ 'file-invalid': isIllegal(type) }"
  >
    <span class="text" style="font-weight: 600"> {{ fileName(name) }} </span>
    <small style="font-size: 12px">({{ isIllegal(type) ? 'inválido' : readableMime(type) }})</small>

    <button
      class="v-button button-round w-fit send button-text button-danger flex items-center justify-center"
      style="width: 25.5px; height: 25.5px; padding: 15px !important"
      @click.stop="$emit('remove')"
      v-if="removable"
    >
      <TrashIcon style="height: 22px; width: 22px; flex-shrink: 0"></TrashIcon>
    </button>
  </div>
</template>

<script setup lang="ts">
import TrashIcon from '@/components/svg/TrashIcon.svg'

import { computed } from 'vue'
import { useIllegalFiles } from './files'

defineEmits<{
  (e: 'remove'): void
}>()
defineProps<{
  name: string
  type: string
  url?: string | null
  removable?: boolean
}>()

const isIllegal = useIllegalFiles()
const fileName = computed(() => (name: string) => {
  const [realName] = name.split('.')

  return realName
})

const readableMime = computed(() => (type: string) => {
  if (type.includes('image')) {
    return 'imagem'
  } else if (type.includes('video')) {
    return 'video'
  } else if (type.includes('audio')) {
    return 'audio'
  } else if (type.includes('pdf')) {
    return 'pdf'
  }

  return 'documento'
})
</script>
