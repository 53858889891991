import type { ApiClient } from '@contactoneteam/c1webapi'
import { extraMaskaInput } from '.'
import { required } from '@/composables/rules'

export const dev: ApiClient = {
  id: 'ef260e9aa3c673af240d17a2660480361a8e081d1ffeca2a5ed0e3219fc18567',
  name: 'staging',
  channelId: '00000000-0000-0000-0000-000000000060',

  shortGuid: true,
  iconless: true,

  extras: {
    fields: [
      extraMaskaInput({
        mask: '###.###.###-##',
        label: 'CPF *',
        help: 'fields.extras.cpf',
        model: 'cpf',
        customData: { type: 'cpf', label: 'CPF' },
        rules: { required }
      })
    ],
  },

  modules: {
    volt: {
      baseUrl: 'https://c1voltapi.voltdigital.com.br/staging',
      params: { code: 'agIk6N9jB/nbvIBr9vhWXpnSyEN8U5fZwnTF0KZnnsfS5ZaFhTP6rg==' }
    }
  }
}
