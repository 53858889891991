import { computed } from 'vue'

const PREFIX = 'd3lkPw=='

function key(k: string) {
  return `${PREFIX}$${k}`
}

export function useLocalStorage(_key: string) {
  function getValue() {
    return localStorage.getItem(key(_key))
  }

  function setValue(v: string) {
    localStorage.setItem(key(_key), v)
  }

  return computed({
    get() {
      return getValue()
    },
    set(v) {
      setValue(v || '')
    }
  })
}
