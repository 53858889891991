<template>
  <Transition name="fade">
    <div class="takeover-notice" v-if="active">
      <slot />
    </div>
  </Transition>
</template>

<script setup lang="ts">
import { useChatWidgetStore } from '@/stores/chatWidget'
import { storeToRefs } from 'pinia'
import { computed } from 'vue'

const chatWidgetStore = useChatWidgetStore()
const { state } = storeToRefs(chatWidgetStore)

const active = computed(() => state.value.meta.notice.active)
</script>
