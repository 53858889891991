<template>
  <div class="message-elements h-fit items-center">
    <div class="dots w-fit">
      <span
        v-for="(element, index) of data?.elements"
        :key="`${elementId}-${index}`"
        :class="{ active: activeIndex === index }"
        class="dot cursor-pointer"
        @click.stop="() => (activeIndex = index)"
      >
      </span>
    </div>

    <Component
      :is="definitions[active.element].component"
      v-bind="{ data: active.elementData, ...definitions[active.element].binds }"
      @next="handleNext"
      @prev="handlePrev"
    ></Component>
  </div>
</template>

<script setup lang="ts">
import { useChatsStore } from '@/stores/chats'
import { storeToRefs } from 'pinia'
import { computed, onMounted, ref } from 'vue'

import BRecipe from './custom/BRecipe.vue'
import { useDataMessage } from '@/composables/messages'
import type { ChatCustomElement } from '@/api/chats'

type Element = {
  component: any
  binds: Record<string, any>
}

const props = defineProps<{
  messageId: string
}>()

const elementId = crypto.randomUUID()

const definitions: { [key: string]: Element } = {
  // recipe component
  recipe: {
    component: BRecipe,
    binds: { parentId: elementId }
  }
}

const active = computed(() => {
  if (!data.value?.elements) throw new Error('cant evaluate active if theres no message')
  return data.value.elements[activeIndex.value]
})

const chatStore = useChatsStore()
const { getMessageById } = storeToRefs(chatStore)

const activeIndex = ref(0)

const message = computed(() => {
  return getMessageById.value(props.messageId)
})

const data = useDataMessage<{
  elements: ChatCustomElement[]
}>(props.messageId)

function handleNext() {
  const targetIndex = activeIndex.value + 1

  if (data.value?.elements[targetIndex]) {
    activeIndex.value = targetIndex
  } else {
    // back to the start
    activeIndex.value = 0
  }
}

function handlePrev() {
  const targetIndex = activeIndex.value - 1

  if (data.value?.elements[targetIndex]) {
    activeIndex.value = targetIndex
  } else {
    // back to the last
    activeIndex.value = (data.value?.elements.length || 1) - 1
  }
}

onMounted(() => {
  if (!message.value) throw new Error('no message')
  if (!data.value?.elements?.length) throw new Error('no elements in message to render')
})
</script>
