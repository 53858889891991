import Chat from './ChatWidget.ce.vue'
import { createApi } from '@contactoneteam/c1webapi'
import { createPinia } from 'pinia'
import { type CETemplate } from '@/types'

export function createCustomElement(): CETemplate {
  return {
    name: 'c1-chat-widget',
    component: Chat,
    install(app) {
      const pinia = createPinia()
      const api = createApi({
        credentials: 'include',
        mode: 'cors',
        headers: {
          Accept: 'application/json'
        }
      })

      app.use(pinia)
      app.use(api)
    },
    hOptions: {},
    props: {}
  }
}

export function getDomElement() {
  return document.querySelector('c1-chat-widget')?.shadowRoot
}
