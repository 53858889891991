<template>
  <div class="v-avatar" :style="resolveStyle" :class="[`${type}-avatar`]">
    <template v-if="!photoUrl?.length">
      <slot v-if="!['001-01', null].includes(id)">
        {{ initials }}
      </slot>
      <template v-else>
        <AvatarIcon style="max-width: 30px; max-height: 30px; color: #272829"></AvatarIcon>
      </template>
    </template>
  </div>
</template>

<script setup lang="ts">
import { getInitials } from '@/composables/helpers'
import { computed, toRefs } from 'vue'
import AvatarIcon from '@/components/svg/AvatarIcon.svg'

const props = defineProps<{
  name: string
  id: string | null
  photoUrl?: string | null
  type?: string
}>()
const { name } = toRefs(props)

const initials = computed(() => {
  return getInitials(name.value)
})

const resolveStyle = computed(() => {
  if (props.photoUrl) {
    return { 'background-image': `url(${props.photoUrl})`, 'background-size': 'cover' }
  }

  return {
    'background-color': 'rgba(244, 244, 245, 0.77)'
  }
})
</script>
