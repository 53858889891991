import type { ApiClient } from '@contactoneteam/c1webapi'

export const fmu: ApiClient = {
  id: '9b3c83a18bb88e03f4744b393acbc2b73acfdcd74acc7b585b72a5bbd468bebf',
  name: 'fmu',
  channelId: '00000000-0000-0000-0000-000000000080',

  shortGuid: true,
  iconless: false,

  modules: {
    volt: {
      baseUrl: 'https://c1voltapi-fmu.voltdigital.com.br/fmu',
      params: { code: '4LHaf5oxskFVQq69XIFVhHClcp234cSaMkGzJ3qEkv63AzFuYmSzZw==' }
    }
  }
}
