import { createApp, defineCustomElement, getCurrentInstance, h } from 'vue'
import type { CETemplate } from './types'

export const transformElementInstance = ({ component, install, props, hOptions }: CETemplate) => {
  return defineCustomElement({
    ...component,
    props,
    render: () => h(component, hOptions),
    setup() {
      const app = createApp(component)

      install(app)

      const inst = getCurrentInstance() as any

      Object.assign(inst.appContext, app._context)
      Object.assign(inst.provides, app._context.provides)
    }
  })
}

export function createCE(tpl: CETemplate) {
  try {
    const ce = transformElementInstance(tpl)

    customElements.define(tpl.name, ce)
  } catch (error: any) {
    console.log('Cannot create a Custom Element from given object', tpl.component)
    throw error
  }
}
