<template>
  <textarea v-model="text" ref="inputEl" @input="handleChange"></textarea>
</template>

<script setup lang="ts">
import { computed, nextTick, onMounted, ref } from 'vue'

const emit = defineEmits<{
  (e: 'update:modelValue', v: string): void
}>()
const props = withDefaults(
  defineProps<{
    modelValue: string
    autogrow?: boolean
    maxHeight?: number
  }>(),
  {
    maxHeight: 180
  }
)

const text = computed({
  get() {
    return props.modelValue
  },
  set(t: string) {
    emit('update:modelValue', t)
  }
})

const inputEl = ref()

function handleChange() {
  if (props.autogrow) handleAutogrow()
}

function handleAutogrow() {
  nextTick(() => {
    inputEl.value.style.height = 'auto'

    const lineHeight = 16
    const { scrollHeight } = inputEl.value
    const rows = Math.ceil(scrollHeight / lineHeight)
    const h = lineHeight * rows

    inputEl.value.style.height = `${h <= props.maxHeight ? h : props.maxHeight}px`
  })
}

onMounted(() => handleChange())

defineExpose({
  inputEl,
  handleAutogrow
})
</script>
