import type { LocaleValues } from '@/composables/i18n'

export const locales: LocaleValues = {
  actions: {
    cancel: 'Cancel',
    close: 'Close',
    confirm: 'Confirm',
    continue: 'Continue',
    save: 'Save',
    see: 'See',
    send: 'Send',
    endChat: 'End Chat',
    newChat: 'New Chat',
    viewChat: 'View Chat',
    loggedOut: 'Logged Out',
    logout: 'Logout',
    type: 'Type'
  },
  fields: {
    email: 'Email',
    password: 'Password',
    phone: 'Phone',
    name: 'Name',
    extras: {
      cpf: 'Type a valid CPF'
    }
  },
  errors: {
    required: 'This field is required',
    email: 'Invalid email',
    password: 'Invalid password',
    phone: 'Invalid phone'
  },
  messages: {
    queue: 'You are in queue',
    ended: {
      title: 'Chat session ended',
      description: 'Thank you for chatting with us.'
    },
    robot: 'Robot'
  },
  tips: {
    email: 'Type a valid email',
    password: 'Type a valid password',
    phone: 'Type a valid phone',
    name: 'Type your name'
  },
  headers: {
    identification: 'Identification'
  }
}
