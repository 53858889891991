import { locales as br } from '@/locales/br'
import { locales as en } from '@/locales/en'
import { useApi } from '@contactoneteam/c1webapi'
import { computed, reactive, ref, watch } from 'vue'

export const i18n = _useI18n()
export const useI18n = () => i18n

function _useI18n() {
  const locale = ref<Locale>((useApi().client?.locale as Locale) || 'br')
  const localeData: Locales = reactive({
    en,
    br
  })

  const currentLocale = computed(() => localeData[locale.value])

  watch(
    locale,
    (l) => {
      // set the lang in the body
      localStorage.setItem('locale', l)
      document.querySelector('#chat_toplevel')?.setAttribute('lang', l)
    },
    {
      immediate: true
    }
  )

  function navigateValue(obj: any, path: string) {
    try {
      return path.split('.').reduce((o, k) => (o || {})[k], obj)
    } catch {
      return ''
    }
  }

  const t = computed(() => (path: string) => {
    return navigateValue(currentLocale.value, path) || path
  })

  return {
    t,
    locale
  }
}

export type LocaleValues = {
  [key: string]: any
}
export type Locale = 'en' | 'br'
export type Locales = {
  [key in Locale]: LocaleValues
}
