import type { Api } from '@contactoneteam/c1webapi'

export type OperationType =
  | 'GetUserStatus'
  | 'GetUserStats'
  | 'GetChat'
  | 'GetChats'
  | 'CloseChat'
  | 'GetActivities'
  | 'GetChatMessages'

export interface UpdatesQuery {
  contactId: string
}

export interface UpdateOperation {
  operationName: OperationType
  operationKey: string | null
}

type GetUpdatesRequest = {
  data: UpdatesQuery
}
type GetUpdatesResponse = UpdateOperation[]

export const GetUpdates: Api<GetUpdatesRequest, GetUpdatesResponse> = {
  method: 'post',
  url: 'updates',
  headers: {}
}
