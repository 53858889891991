import type { ApiClient } from '@contactoneteam/c1webapi'

import { dev } from './dev'
import { digimais } from './digimais'
import { fmu } from './fmu'
import { ignite } from './ignite'

export const clients: ApiClient[] = [dev, digimais, fmu, ignite]

export const getClientById = (clientId: string) => clients.find(({ id }) => id === clientId)

type ExtraMaskInput = {
  mask: string
  label: string
  help: string
  model: 'cpf'
  rules: any
  customData: {
    type: 'cpf'
    label: 'CPF'
  }
}
export function extraMaskaInput({ mask, label, help, model, customData, rules }: ExtraMaskInput) {
  return {
    type: 'maska-input',
    bindings: {
      mask,
      label,
      help,
      model,
      customData,
      rules
    }
  }
}
