import type { ChatMessage } from '@/api/chats'
import { getDomElement } from '@/components/ChatWidget'
import { useChatsStore } from '@/stores/chats'
import { storeToRefs } from 'pinia'
import { computed } from 'vue'

export function useDataMessage<TDataType = any>(messageId: string) {
  const chatStore = useChatsStore()
  const { getMessageById } = storeToRefs(chatStore)

  return computed(() => {
    try {
      return JSON.parse(getMessageById.value(messageId)?.content.text || '') as TDataType
    } catch (error: any) {
      return null
    }
  })
}

type SendMessageData = Pick<ChatMessage, 'content' | 'customData'>
export function useSendMessage() {
  const chatsStore = useChatsStore()

  async function sendMessage({ content, customData }: SendMessageData) {
    await chatsStore.sendMessage({
      id: '',
      content,
      customData,
      sentAt: new Date().toISOString()
    })
  }

  async function scrollToBottom(delay = 100) {
    setTimeout(() => {
      const shadow = getDomElement()

      if (shadow) {
        const elem = shadow.getElementById('messages-scrollspy')

        if (elem) {
          elem.scroll({
            top: elem.scrollHeight,
            behavior: 'smooth'
          })
        }
      } else {
        throw new Error('shadow does not exist')
      }
    }, delay)
  }

  return {
    sendMessage,
    scrollToBottom
  }
}
