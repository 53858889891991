import { Volt } from '@/api/Volt'
import {
  Status,
  closedStatuses,
  type Chat,
  type ChatMessage,
  type PostableChatMessage,
  type MessageContent,
  type CustomData
} from '@/api/chats'
import { runApi, useApi } from '@contactoneteam/c1webapi'
import { defineStore } from 'pinia'
import { computed, reactive, ref } from 'vue'
import { useContactsStore } from './contacts'
import { useUpdatesStore } from './updates'
import { useChatWidgetStore } from './chatWidget'
import { useSendMessage } from '@/composables/messages'
import { useLocalStorage } from '@/composables/storage'

type StoredMessage = ChatMessage & { eager: boolean; error: boolean }
export type LocalAttachment = {
  file: File
  name: string
  type: string
}

export const useChatsStore = defineStore('chats', () => {
  const chatId = ref<string>()
  const chat = ref<Chat | null>(null)
  const messages = ref<StoredMessage[]>([])

  /**
   * Temporary storage for attachments to be sent between the messages container and the input.
   */
  const temporaryAttachments: {
    items: LocalAttachment[]
    visible: boolean
  } = reactive({
    items: [],
    visible: false
  })

  const widgetStore = useChatWidgetStore()
  const contactStore = useContactsStore()
  const updatesStore = useUpdatesStore()
  const api = useApi()
  const chatSession = useLocalStorage('ch')

  let interval: NodeJS.Timeout

  function startUpdates() {
    interval = setInterval(async () => {
      if (!contactStore.contact || isEnded.value) return

      await updatesStore.getUpdates({
        contactId: contactStore.contact.id
      })
    }, 6 * 1000)
  }

  function stopUpdates() {
    if (interval) clearInterval(interval)
  }

  const isBot = computed(() => {
    if (chat.value?.customData || chat.value?.customData?.botContextData) {
      return true
    }

    return false
  })

  const getLastMessage = computed(() => {
    if (messages.value.length) {
      return messages.value[messages.value.length - 1]
    }

    return null
  })
  const getMessageByContent = computed(() => (c: MessageContent, fromId: string) => {
    return messages.value.find(({ content, from: { id } }) => content === c && fromId === id)
  })
  const getMessageById = computed(() => (messageId: string) => {
    return messages.value.find(({ id }) => id === messageId)
  })
  const messageHasBeenRepliedTo = computed(() => (messageId: string) => {
    return messages.value.find((message) => {
      return message.replyMessageId === messageId
    })
  })

  const ended = computed(() => {
    return closedStatuses.includes(chat.value?.statusId as any)
  })

  const from = computed(() => {
    if (chat.value) {
      return chat.value.from.filter(({ id }) => id !== contactStore.contact?.id)
    }

    return []
  })

  const isQueue = computed(() => {
    if (chat.value) {
      return [Status.Queue, Status.New].includes(chat.value.statusId)
    }

    return false
  })
  const isChatting = computed(() => {
    if (chat.value) {
      return [Status.Chatting, Status.Hold].includes(chat.value.statusId)
    }

    return false
  })
  const isEnded = computed(() => {
    if (chat.value) {
      return [Status.Ended].includes(chat.value.statusId)
    }

    return false
  })

  const getMessageIndexById = computed(() => (messageId: string) => {
    return messages.value.findIndex(({ id }) => id === messageId)
  })

  async function closeChat() {
    if (!chat.value) throw new Error()
    if (!contactStore.contact) throw new Error()

    const response = await runApi(Volt.CloseChat, {
      params: {
        chatId: chat.value.id
      },
      data: {
        contactId: contactStore.contact.id
      }
    })

    chat.value.statusId = Status.Ended
    widgetStore.setNotice('end')

    return response
  }

  async function getChatMessages() {
    if (!contactStore.contact) throw new Error('no contact')
    if (!chat.value) throw new Error('no chat to get messages from')

    const response = await runApi(Volt.GetChatMessages, {
      data: {
        pagingFrom: 0,
        pagingSize: 400,
        contactId: contactStore.contact.id
      },
      params: {
        chatId: chat.value.id
      }
    })

    addMessages(response.data.messages)

    return response
  }

  function addMessages(data: ChatMessage[]) {
    for (const message of data) {
      const idx = getMessageIndexById.value(message.id)

      if (idx !== -1) {
        messages.value[idx] = {
          ...messages.value[idx],
          ...message
        }
      } else {
        const eagerMessage = getMessageByContent.value(message.content, message.from.id)

        if (eagerMessage) {
          messages.value[messages.value.indexOf(eagerMessage)] = {
            ...messages.value[messages.value.indexOf(eagerMessage)],
            ...message
          }
        } else {
          messages.value.push({
            ...message,
            eager: false,
            error: false
          })
        }
      }
    }
  }

  function getEventableContact(): {
    fromUid: string
    fromId: string
    fromName: string
    fromPhotoUrl: string | null
  } | null {
    if (contactStore.contact) {
      const contact = contactStore.contact

      return {
        fromId: contact.id,
        fromName: contact.name || '',
        fromPhotoUrl: contact.photoUrl || '',
        fromUid: contact.uid || ''
      }
    }

    return null
  }

  async function uploadStorage(files: LocalAttachment[]) {
    if (!chatId.value || !contactStore.contact)
      throw new Error('no chat or contact to attach file to')

    const form = new FormData()

    form.append('chatId', chatId.value)
    form.append('contactId', contactStore.contact.id)

    for (const { file } of files) form.append('fileContent', file)

    return await runApi(Volt.UploadFile, {
      data: form
    })
  }

  async function createChat(customData: CustomData | null = null) {
    if (!contactStore.contact) throw new Error('no contact to attach to chat')

    messages.value.splice(0)

    const contact = getEventableContact()

    if (!contact) throw new Error('cant post events without loggin in')

    chatId.value = crypto.randomUUID()

    const response = await runApi(Volt.PostEvent, {
      data: {
        ...contact,
        content: null,
        context: customData,
        messageType: 'session',
        customData: null,
        reaction: null,
        replyMessageId: null,
        sentAt: new Date().toISOString(),
        sourceType: 'webchat',
        to: `webchat-${api.client?.name}`,
        contextId: chatId.value
      }
    })

    // create starter chat object so the ui can load
    // event requisitions are not async so we need to wait till
    // getUpdates points us to getChat
    chat.value = {
      closedAt: null,
      closedBy: null,
      createdAt: new Date().toISOString(),
      from: [{ id: contact.fromId, name: contact.fromName, photoUrl: contact.fromPhotoUrl }],
      customData: null,
      id: chatId.value,
      lastMessageSentAt: new Date().toISOString(),
      pinned: false,
      statusId: Status.Hold,
      activeUsers: [],
      unreadMessages: 0,
      type: 'chat'
    }

    waitChatHello()

    widgetStore.clearNotice()

    chatSession.value = chat.value.id

    return response
  }

  /**
   * Hotfix. Sends hello to trigger message rules.
   * May spam GetChat if the Chat takes too long to be saved.
   * @remove
   */
  async function waitChatHello() {
    try {
      await retryGetChat()
      // const { sendMessage } = useSendMessage()
      // sendMessage({
      //   customData: null,
      //   content: {
      //     text: 'Olá',
      //     type: 'text/plain',
      //     url: null
      //   }
      // })
    } catch (error: any) {
      console.error('Cannot say hello: %s', error.message)
    }
  }

  /**
   * Retries to fetch the chat and returns it if one is found. Max time is defined internally.
   * @returns
   */
  async function retryGetChat() {
    return new Promise((resolve, reject) => {
      async function run() {
        await getChat()
      }

      // current time
      const cT = new Date().getTime()
      // max time
      const mT = cT + 120 * 1000

      const interval: any = setInterval(async () => {
        try {
          const response = await run()
          clearInterval(interval)
          resolve(response)
        } catch (error: any) {
          console.info('chat is not ready')

          if (new Date().getTime() >= mT) reject(new Error('max time reached'))
        }
      }, 2 * 1000)
    })
  }

  async function getChat() {
    if (!contactStore.contact) throw new Error('no contact to attach to chat')
    if (!chatId.value) throw new Error('no chat to get')

    try {
      const response = await runApi(Volt.GetChat, {
        data: {
          contactId: contactStore.contact.id
        },
        params: {
          chatId: chatId.value
        }
      })

      chat.value = response.data
    } catch (error: any) {
      widgetStore.setNotice('end')
    }
  }

  function createEagerMessage(data: PostableChatMessage) {
    if (!contactStore.contact) throw new Error('no owner to attach to the message')
    const id = crypto.randomUUID()
    const message: StoredMessage = {
      id,
      sentAt: new Date().toISOString(),
      from: {
        ...contactStore.contact
      },
      content: data.content,
      customData: data.customData,
      deliveredAt: null,
      liked: false,
      notification: null,
      ordering: messages.value.length,
      read: false,
      replyMessageId: null,
      direction: null,
      readAt: null,
      eager: true,
      error: false,
      externalId: null
    }

    messages.value.push(message)

    return id
  }

  async function sendMessage(data: PostableChatMessage) {
    if (!chat.value) throw new Error('cant send message to no chat')
    if (!data.contactId) data.contactId = contactStore.contact?.id

    const eagerId = createEagerMessage(data)

    let failed = false

    try {
      await runApi(Volt.PostEvent, {
        data: {
          ...(getEventableContact() as any),
          content: data.content,
          contextId: chat.value.id,
          customData: data.customData,
          messageType: 'text',
          reaction: null,
          sentAt: new Date().toISOString(),
          replyMessageId: null,
          sourceType: 'webchat',
          to: `webchat-${api.client?.name}`,
          id: eagerId
        }
      })
    } catch (error: any) {
      failed = true
    }

    const index = getMessageIndexById.value(eagerId)

    messages.value[index].id = eagerId
    messages.value[index].eager = false
    messages.value[index].error = failed
  }

  async function readMessage(messageIds: string[]) {
    if (!chat.value) throw new Error('cant send message to no chat')
    if (!contactStore.contact?.id) throw new Error('no contact')

    const response = await runApi(Volt.ReadChatMessages, {
      data: {
        messageIds,
        contactId: contactStore.contact.id
      },
      params: {
        chatId: chat.value.id
      }
    })

    messageIds.forEach((messageId) => {
      const index = getMessageIndexById.value(messageId)

      if (messages.value[index]) messages.value[index].read = true
      if (messages.value[index]) messages.value[index].readAt = new Date().toISOString()
    })

    return response
  }

  return {
    chat,
    chatId,
    messages,
    temporaryAttachments,

    messageHasBeenRepliedTo,
    getMessageById,
    getLastMessage,
    isQueue,
    isBot,
    isChatting,
    isEnded,
    from,
    ended,

    getChat,
    uploadStorage,
    closeChat,
    createChat,
    readMessage,
    getChatMessages,
    sendMessage,
    startUpdates,
    stopUpdates
  }
})
