import type { ApiClient } from '@contactoneteam/c1webapi'
import { extraMaskaInput } from '.'
import { atLeastTwoNames, cpfSize, required, validateCpf } from '@/composables/rules'
import ChatAvatar from '@/assets/image/band/chat-avatar.png'

export const ignite: ApiClient = {
  id: '5b8af9e5e961575968f7b58564fdd527b898ca76cf364fe1ca8b3c582753796c',
  name: 'ignite',
  channelId: '00000000-0000-0000-0000-000000000080',

  shortGuid: true,
  iconless: true,
  x: 'left',

  persona: {
    name: 'BandBet',
    chatAvatar: ChatAvatar,
  },

  extras: {
    fields: [
      extraMaskaInput({
        mask: '###.###.###-##',
        label: 'CPF *',
        help: 'fields.extras.cpf',
        model: 'cpf',
        customData: { type: 'cpf', label: 'CPF' },
        rules: { required, cpfSize, validateCpf },
      })
    ],
    overrides: {
      name: {
        rules: {
          atLeastTwoNames
        }
      }
    }
  },

  modules: {
    volt: {
      baseUrl: 'https://c1voltapi-ignite.voltdigital.com.br/ignite',
      params: { code: '3OTqGFOpcPCdvjkj5R_qa2sw7_toI1oCKd3X1bkufXrBAzFuqgL2Fg==' }
    }
  }
}
