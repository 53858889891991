<template>
  <div class="message-interactions" :class="{ disabled: disabled, single: options.length === 1 }">
    <VButton
      class="button-text"
      v-for="(option, i) of options"
      :key="`opt-${i}`"
      @click="select(option)"
    >
      {{ getLabel(option) }}
    </VButton>
  </div>
</template>

<script setup lang="ts">
import { useChatsStore } from '@/stores/chats'
import { storeToRefs } from 'pinia'
import { computed, toRefs } from 'vue'
import type { InteractiveButton, InteractiveListButton } from '@/api/chats'

import VButton from './form/VButton.vue'

const props = defineProps<{
  messageId: string
  messageIndex: number
}>()
const { messageId } = toRefs(props)

const chatStore = useChatsStore()
const { getMessageById, messageHasBeenRepliedTo, ended } = storeToRefs(chatStore)

const message = computed(() => {
  return getMessageById.value(messageId.value)
})

const disabled = computed(() => {
  return messageHasBeenRepliedTo.value(messageId.value) || ended.value
})

const options = computed(() => {
  return message.value?.customData?.buttons || message.value?.customData?.options || []
})

type OptionMaybe = InteractiveButton | InteractiveListButton
const getLabel = computed(() => (i: OptionMaybe) => {
  return (i as InteractiveButton)?.label || (i as InteractiveListButton)?.title
})
const getValue = computed(() => (i: OptionMaybe) => {
  return (i as InteractiveButton)?.value || (i as InteractiveListButton)?.id
})

async function select(i: OptionMaybe) {
  if (disabled.value) return

  const text = getValue.value(i)
  // true if is options
  const options = 'title' in i
  const replyMessageId = messageId.value

  const content = {
    text,
    type: 'text/plain',
    url: null
  }
  const sentAt = new Date().toISOString()

  if (options) {
    await chatStore.sendMessage({
      id: '',
      content,
      replyMessageId,
      sentAt,
      customData: {
        options: [i as InteractiveListButton],
        buttons: null,
        contextData: null
      }
    })
  } else {
    await chatStore.sendMessage({
      content,
      sentAt,
      id: '',
      replyMessageId,
      customData: {
        buttons: [i as InteractiveButton],
        options: null,
        contextData: null
      }
    })
  }
}
</script>
