import type { ApiClient } from '@contactoneteam/c1webapi'

export const digimais: ApiClient = {
  id: '14603de1a63fa6c1331d69c278d6d32f2bbd825b28c5e4053e6418b24c7e761f',
  name: 'digimais',
  channelId: '00000000-0000-0000-0000-000000000080',

  shortGuid: true,
  iconless: false,

  modules: {
    volt: {
      baseUrl: 'https://c1voltapi-digimais.voltdigital.com.br/digimais',
      params: { code: 'MaghvWfZQSc9xtDd-SzYD8mPTpUjpF4VbobBaG1r3gYtAzFucwf_HA==' }
    }
  }
}
