import { Volt } from '@/api/Volt'
import type { ContactInformation } from '@/api/chats'
import type { CreateContactPayload, LoginContactPayload } from '@/api/contacts'
import { runApi, useApi } from '@contactoneteam/c1webapi'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

export const useContactsStore = defineStore('contacts', () => {
  const contact = ref<ContactInformation & { authenticated: boolean }>()
  const api = useApi()

  const softAuthenticated = computed(() => contact.value?.authenticated)

  let refreshInterval: any

  function clearRefreshes() {
    if (refreshInterval) clearInterval(refreshInterval)
  }

  function startRefreshes() {
    refreshInterval = setInterval(async () => {
      try {
        await refreshToken()
      } catch (error: any) {
        if (error.status === 401) {
          await loginContact({
            channelId: api.client?.channelId || '',
            contactId: contact.value?.id,
            name: contact.value?.name,
            uid: contact.value?.uid,
            photoUrl: null
          })
        }
      }
    }, 60 * 1000)
  }

  /**
   * Uses LoginContact to create and login the contact.
   * @param data
   * @param login
   * @returns
   */
  async function createContact({
    channelId,
    name,
    uid,
    photoUrl,
    contactId
  }: CreateContactPayload) {
    const response = await loginContact({
      channelId,
      name,
      uid,
      contactId,
      photoUrl
    })

    return response
  }

  async function refreshToken() {
    return await runApi(Volt.RefreshToken)
  }

  async function loginContact(data: LoginContactPayload) {
    const response = await runApi(Volt.LoginContact, {
      data
    })

    contact.value = {
      authenticated: false,
      id: response.data.contactId,
      name: data.name,
      uid: data.uid,
      photoUrl: data.photoUrl,
      type: 'contact'
    }

    contact.value.authenticated = true

    return response
  }

  return {
    contact,
    softAuthenticated,
    
    refreshToken,
    loginContact,
    createContact,
    startRefreshes,
    clearRefreshes,

    $reset() {
      clearRefreshes()
    }
  }
})
