export function getInitials(name: string) {
  const words = name.split(' ')

  const firstInitial = words[0] ? words[0].charAt(0) : ''

  const secondInitial = words.length > 1 ? words[1].charAt(0) : firstInitial

  const initials = `${firstInitial}${secondInitial}`

  return initials.toUpperCase() // Convert to uppercase for consistency
}

export function time(d: Date, seconds = false) {
  const t = d.toLocaleTimeString()

  if (seconds) return t

  return t.split(':').slice(0, 2).join(':')
}

/**
 * Picks a random element from the provided ArrayLike.
 * @param x
 * @returns
 */
export function pick<T>(x: ArrayLike<T>) {
  return x[Math.floor(Math.random() * x.length)]
}

export function onlyDigits(value: string) {
  return value.replace(/\D/g, '')
}
