import { createModule } from '@contactoneteam/c1webapi'

import * as Chats from './chats'
import * as Contacts from './contacts'
import * as Updates from './updates'

export const Volt = createModule({
  ...Chats,
  ...Contacts,
  ...Updates
})
