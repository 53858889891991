<template>
  <template v-if="message">
    <Transition name="fade">
      <div
        :class="[
          positionClass,
          {
            'message-confirmed': !message.eager,
            'message-error': message.error,
            'with-media': isMedia
          }
        ]"
        class="chat-message"
        :spy-useable="isSpyUseable"
        :data-id="message.id"
        v-if="!message.notification && visible"
      >
        <div class="avatar-container">
          <template v-if="isAvatarViewable">
            <VAvatar
              :id="message.from.id"
              :name="
                message.from.name || message.from.editedName || message.from.userFullName || ''
              "
              :type="message.from.type"
              :photoUrl="photoUrl"
            ></VAvatar>
          </template>
          <template v-else> &nbsp; </template>
        </div>
        <div class="message-container">
          <div class="body-wrapper">
            <div class="body" v-if="isTextlike">
              <div
                class="flex flex-row flex-wrap"
                style="gap: 5px; margin-bottom: 5px"
                v-if="message.customData?.attachments?.length"
              >
                <FileChip
                  @click="seeFile(attachment.contentUrl || '')"
                  v-for="(attachment, idx) of message.customData.attachments"
                  :key="`real-attachment-${idx}-${message.id}`"
                  class="cursor-pointer"
                  v-bind="{
                    name: attachment.contentName || '',
                    type: attachment.contentType || ''
                  }"
                  :removable="false"
                  style="color: var(--color-text)"
                ></FileChip>
              </div>
              <span v-if="!isURL(message.content.text || '')" v-html="text" />
              <!-- <a
                target="_blank"
                v-else-if="isURL(message.content.text || '')"
                :href="message.content.text"
              >
                {{ text }}
              </a> -->
              <template v-else-if="message.content.url">
                <ChatMessageMedia v-bind="{ messageId }" />
              </template>

              <span class="status">
                <small>{{ sentAt }}</small>
                <i :class="{ active: isRead }" v-if="!isNotFromSelf">
                  <DblCheckIcon v-if="isDelivered || isRead" />
                  <SingleCheckIcon v-else />
                </i>
              </span>
            </div>
            <ChatMessageElements v-if="isData" v-bind="{ messageId }"></ChatMessageElements>

            <ChatMessageActions
              v-bind="{ messageId: message.id, messageIndex: index }"
              v-if="
                (message.customData?.buttons?.length || message.customData?.options?.length) &&
                isNotFromSelf
              "
            />
          </div>
        </div>
      </div>
    </Transition>
  </template>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { computed, onMounted, ref } from 'vue'
import { useChatsStore } from '@/stores/chats'

import SingleCheckIcon from './svg/SingleCheckIcon.svg'
import DblCheckIcon from './svg/DblCheckIcon.svg'
import VAvatar from './form/VAvatar.vue'
import FileChip from './FileChip.vue'
import ChatMessageActions from './ChatMessageActions.vue'
import ChatMessageMedia from './ChatMessageMedia.vue'
import ChatMessageElements from './ChatMessageElements.vue'
import { time } from '@/composables/helpers'
import { useApi } from '@contactoneteam/c1webapi'

const props = defineProps<{
  messageId: string
  index: number
}>()

// const contactStore = useContactsStore()
const chatsStore = useChatsStore()
const api = useApi()
const { getMessageById, messages } = storeToRefs(chatsStore)

const visible = ref(false)

const photoUrl = computed(() => {
  if (['bot', 'system'].includes(message.value?.from.type || '')) {
    return api.client?.persona?.chatAvatar || undefined
  }

  return message.value?.from.photoUrl
})

const isRead = computed(() => message.value?.read === true)
const isDelivered = computed(() => (message.value?.deliveredAt?.length || 0) > 0)
const isMedia = computed(() => message.value?.content.type !== 'text/plain')
const isTextlike = computed(
  () =>
    ['text/plain'].includes(message.value?.content.type || '') || message.value?.content.text.length
)
const isData = computed(() => ['text/json'].includes(message.value?.content.type || ''))

const parseEscapes = computed(() => (input: string) => {
  return input.replace(/[\n]/g, '<br/>')
})

const text = computed(() => {
  if (!isNotFromSelf.value) {
    if (message.value?.customData?.options?.length) return message.value.customData.options[0].title

    if (message.value?.customData?.buttons?.length) {
      return message.value.customData.buttons[0].label
    }
  }

  return parseEscapes.value(message.value?.content.text || '')
})

const isNotFromSelf = computed(() => {
  return message.value?.from.type !== 'contact'
})

const positionClass = computed(() => {
  return !isNotFromSelf.value ? 'message-reverse' : ''
})

const message = computed(() => {
  return getMessageById.value(props.messageId)
})

const sentAt = computed(() => {
  if (message.value) {
    return time(new Date(message.value.sentAt), false)
  }

  return ''
})

const isAvatarViewable = computed(() => {
  if (!previousMessage.value) return true
  if (message.value?.notification) return false
  if (message.value?.from.type === 'bot' && previousMessage.value.from.type === 'system')
    return true
  return previousMessage.value.from.id !== message.value?.from.id && isTextlike.value
})

const previousMessage = computed(() => {
  if (props.index === 0) return null

  return messages.value[props.index - 1]
})

const isSpyUseable = computed(() => {
  return isNotFromSelf.value && message.value?.read === false ? 'true' : 'false'
})

function seeFile(url: string) {
  window.open(url, '_blank')?.focus()
}

function isURL(input: string) {
  // Regular expression for a simple URL pattern
  var urlPattern = /^(https?:\/\/)?([\w.-]+)\.([a-z]{2,})(\/\S*)?$/

  // Test the input string against the pattern
  return urlPattern.test(input)
}

onMounted(() => {
  visible.value = true
})
</script>
