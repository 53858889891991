import type { LocaleValues } from '@/composables/i18n'

export const locales: LocaleValues = {
  actions: {
    cancel: 'Cancelar',
    close: 'Fechar',
    confirm: 'Confirmar',
    continue: 'Continuar',
    save: 'Salvar',
    see: 'Ver',
    send: 'Enviar',
    endChat: 'Finalizar Chat',
    newChat: 'Novo Chat',
    viewChat: 'Visualizar Chat',
    logout: 'Logout',
    loggedOut: 'Deslogado',
    type: 'Digitar'
  },
  fields: {
    email: 'Email',
    password: 'Senha',
    phone: 'Telefone',
    name: 'Nome',
    extras: {
      cpf: 'Digite um CPF válido'
    }
  },
  errors: {
    required: 'Este campo é obrigatório',
    email: 'Email inválido',
    password: 'Senha inválida',
    phone: 'Telefone inválido'
  },
  messages: {
    queue: 'Você está na fila',
    ended: {
      title: 'A sessão de chat acabou',
      description: 'Obrigado por conversar conosco.'
    },
    robot: 'Robô'
  },
  tips: {
    email: 'Digite um email válido',
    password: 'Digite uma senha válida',
    phone: 'Digite um telefone válido',
    name: 'Digite seu nome completo'
  },
  headers: {
    identification: 'Identificação'
  }
}
