import type { Api } from '@contactoneteam/c1webapi'

export enum Status {
  New = '4f2b997c-3b3d-4d9e-b15d-ef3145e54f07',
  Queue = '9fa62488-75f7-4819-9c1a-d9d77bc1650c',
  Chatting = '75dfc30a-4b40-4397-9100-5d799c857a59',
  Ended = 'ce22b331-f65e-40b5-8f76-4e28e1af1869',
  Ending = '0b219094-5ade-4ce2-886b-e1f0546232a4',
  Hold = '47c3b061-6a1c-48ba-8420-fc210f21395b'
}

export const closedStatuses = [Status.Ended]

export type NotificationAction =
  | 'CHAT SKILL ASSIGNED'
  | 'CHAT USER ASSIGNED'
  | 'PHONECALL MADE'
  | 'PHONECALL RECEIVED'
  | 'PHONECALL DISCONNECTED'
  | 'CHAT CREATED'
  | 'INTERNAL CHAT CREATED'
  | 'CHAT CLOSED'
  | 'CHAT CATEGORIZED'
  | 'CHAT PINNED'
  | 'CHAT STATUS CHANGED'
  | 'USERS ADDED'
  | 'CONTACTS ADDED'
  | 'USERS REMOVED'
  | 'CONTACTS REMOVED'
  | 'CHAT TRANSFERRED'
  | 'ACTION SET TAGS'
  | 'ACTION UNSET TAGS'
  | 'ACTION POST CHAT MESSAGE'
  | 'ACTION SEND TO BOT'
  | 'ACTION CREATE CRM ACTIVITY'

type ChatCustomDataValue = any | null

export interface PostableChatMessage {
  id: string
  contactId?: string
  sentAt: string
  content: MessageContent
  customData: MessageCustomData | null
  replyMessageId?: string
}

export type ChatCustomData = {
  [key: string]: ChatCustomDataValue
}

export type InteractiveButton = {
  label: string
  value: string
}
export type InteractiveListButton = {
  id: string
  title: string
}

export type ChatCustomElement = {
  element: string
  elementData: any
}

export type MessageCustomData = {
  subject?: string
  attachments?: LongMessageContentType[]
  buttons?: InteractiveButton[] | null
  options?: InteractiveListButton[] | null
  [key: string]: ChatCustomDataValue
}

export type BotCustomData = {
  applicationId: string | null
  contextData: Record<string, any> | null
  contextId: string | null
  ruleId: string | null
}

export interface ContactInformation {
  name?: string
  editedName?: string
  userFullName?: string
  photoUrl?: string | null
  type?: 'user' | 'contact' | 'system' | 'bot'
  uid?: string
  id: string
}

/**
 * Representa as informações basicas de um membro na Conversa.
 */
export interface ChatMemberInformation {
  id: string
  name?: string
  photoUrl?: string | null
}

/**
 * Define um chat.
 */
export interface Chat {
  id: string
  statusId: Status
  lastMessageSentAt: string
  answeredAt?: string
  disconnectedAt?: string
  unreadMessages: number
  pinned: boolean
  activeUsers: ChatMemberInformation[]
  /**
   * Contém todos os participantes do Chat, sendo a posição `0` da coleção o Cliente.
   */
  from: Array<ContactInformation>

  createdAt: string
  closedAt: string | null
  closedBy: ContactInformation | null

  type: string

  customData: ChatCustomData | null
}

export interface MessageContent {
  type: string
  url: string | null
  text: string
}

export interface LongMessageContentType {
  contentType?: string
  contentUrl?: string
  contentName?: string
}

/**
 * Define uma Mensagem.
 */
export interface ChatMessage {
  id: string
  sentAt: string
  deliveredAt: string | null
  readAt: string | null
  ordering: number
  direction: 'I' | 'O' | 'S' | null
  read: boolean
  liked: boolean
  from: ContactInformation
  content: MessageContent
  customData: MessageCustomData | null
  notification: NotificationAction | null
  externalId: string | null
  replyMessageId: string | null
}

type PostChatMessageRequest = {
  params: {
    chatId: string
  }
  data: PostableChatMessage
}
type PostChatMessageResponse = {
  id: string
}

type GetChatRequest = {
  params: {
    chatId: string
  }
  data: {
    contactId: string
  }
}
type GetChatResponse = Chat

type GetChatMessagesRequest = {
  data: {
    pagingFrom: number | null
    pagingSize: number
    contactId: string
  }
  params: {
    chatId: string
  }
}
type GetChatMessagesResponse = {
  pagingNext: number | null
  messages: ChatMessage[]
}

type CloseChatRequest = {
  params: {
    chatId: string
  }
  data: {
    contactId: string
  }
}
type CloseChatResponse = null

type ReadChatMessagesRequest = {
  params: {
    chatId: string
  }
  data: {
    contactId: string
    messageIds: string[]
  }
}
type ReadChatMessagesResponse = null

export type CustomData = {
  botCustomData: { type: string; label: string; value: string }[]
}

type PostEventRequest = {
  data: {
    messageType: 'session' | 'text'
    sourceType: 'webchat'
    contextId: string
    context: CustomData | null
    fromUid: string
    fromId: string
    fromName: string
    fromPhotoUrl: string | null
    to: `webchat-${string}`
    sentAt: string
    content: {
      type: string
      text: string
      url: null | string
    } | null
    replyMessageId: string | null
    reaction: string | null
    customData: CustomData | null
    id?: string
  }
}
type PostEventResponse = any

export type StorageFile = {
  url: string
  type: string
  size: number
  text: string | undefined
}

type UploadFileRequest = {
  data: FormData
  // {
  //   fileContent: any
  //   chatId: string
  //   userId: string
  // }
}
type UploadFileResponse = {
  contents: StorageFile[]
}

/**
 * Método para fazer upload de um arquivo para a Storage do Volt.
 */
export const UploadFile: Api<UploadFileRequest, UploadFileResponse> = {
  method: 'post',
  url: 'contents',
  headers: {
    'Content-Type': 'multipart/form-data'
  }
}

export const PostEvent: Api<PostEventRequest, PostEventResponse> = {
  method: 'post',
  url: 'event',
  headers: {}
}

export const ReadChatMessages: Api<ReadChatMessagesRequest, ReadChatMessagesResponse> = {
  method: 'post',
  url: 'chats/:chatId/messages/read',
  headers: {}
}

export type CreateExternalChatPayload = {
  contactId: string
  channelId: string
  toUserId?: string
  externalId?: string
  customData?: Partial<ChatCustomData>
}

export const CloseChat: Api<CloseChatRequest, CloseChatResponse> = {
  method: 'post',
  url: 'chats/:chatId/close',
  headers: {}
}

export const PostChatMessage: Api<PostChatMessageRequest, PostChatMessageResponse> = {
  method: 'post',
  url: 'chats/:chatId/messages/post',
  headers: {}
}

export const GetChat: Api<GetChatRequest, GetChatResponse> = {
  method: 'post',
  url: 'chats/:chatId',
  headers: {}
}

export const GetChatMessages: Api<GetChatMessagesRequest, GetChatMessagesResponse> = {
  method: 'post',
  url: 'chats/:chatId/messages',
  headers: {}
}
