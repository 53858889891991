<template>
  <button
    class="v-chat-button"
    :class="[{ active: state.meta.button }]"
    @click="() => chatWidgetStore.toggleButtonState()"
  >
    <span class="inner flex flex-middle" :style="innerStyle">
      <template v-if="!iconHidden">
        <ChatIcon v-if="!state.meta.button" class="chat-icon"></ChatIcon>
        <CloseIcon class="close-icon" v-else></CloseIcon>
      </template>
    </span>
  </button>
</template>

<script setup lang="ts">
import { ignite } from '@/api/clients/ignite'
import ChatIcon from './svg/ChatIcon.svg'
import CloseIcon from './svg/CloseCircleIcon.svg'

// Logos
import IgniteLogo from '@/assets/image/band/bandbet.png'

import { useChatWidgetStore } from '@/stores/chatWidget'
import { useApi } from '@contactoneteam/c1webapi'
import { storeToRefs } from 'pinia'
import { computed } from 'vue'

const chatWidgetStore = useChatWidgetStore()
const { state } = storeToRefs(chatWidgetStore)

const api = useApi()

const baseBackgroundStyle = {
  'background-size': 'contain',
}

const innerStyle = computed(() => {
  if (api.client?.id === ignite.id) {
    return { 'background-image': `url(${IgniteLogo})`, ...baseBackgroundStyle }
  }

  return undefined
})

const iconHidden = computed(() => {
  return api.client?.iconless
})
</script>
