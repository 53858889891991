<template>
  <div class="v-chat-window" :style="windowStyle">
    <ChatNavbar></ChatNavbar>
    <!-- Chat Interface -->
    <ChatView v-if="chat !== null"></ChatView>
    <!-- Form/Presentation View -->
    <ChatForm v-else-if="state.meta.chat.state"></ChatForm>

    <!-- Others -->
  </div>
</template>

<script setup lang="ts">
import { useChatWidgetStore } from '@/stores/chatWidget'
import { storeToRefs } from 'pinia'
import { computed } from 'vue'

import ChatNavbar from './ChatNavbar.vue'
import ChatView from './ChatView.vue'
import ChatForm from './ChatForm.vue'

import Pattern from '@/assets/image/funpattern.png'
import { useChatsStore } from '@/stores/chats'
const chatWidgetStore = useChatWidgetStore()
const chatStore = useChatsStore()
const { chat } = storeToRefs(chatStore)
const { state } = storeToRefs(chatWidgetStore)

const windowStyle = computed(() => {
  return { 'background-image': `url(${Pattern})` }
})
</script>
