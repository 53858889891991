<template>
  <div class="v-chat-navbar relative">
    <template v-if="isIdentification">
      <span class="w-full row items-center">
        {{ t('headers.identification') }}
      </span>
      <VButton class="button-text button-round action justify-center" @click="() => toggleWidget()">
        <ChevronDownIcon></ChevronDownIcon>
      </VButton>
    </template>
    <template v-else-if="isChat">
      <div class="nav-avatar">
        <VAvatar
          :id="from.id"
          :name="from.name || from.editedName || from.userFullName || ''"
          :photo-url="from.photoUrl"
        ></VAvatar>
        <span class="name">{{ privacyName(from) }}</span>
      </div>

      <div class="chat-menu">
        <VDropdown ref="menu">
          <template v-slot="{ toggle }">
            <VButton class="button-text button-round action justify-center" @click="() => toggle()">
              <MenuIcon />
            </VButton>
          </template>

          <template v-slot:items>
            <div @click="logout" class="item" :class="{ disabled: loggedOut }">
              <LeaveIcon class="icon"></LeaveIcon>
              <span>{{ loggedOut ? t('actions.loggedOut') : t('actions.logout') }}</span>
            </div>
            <div
              class="item"
              :class="{ disabled: loadingStates.close }"
              v-if="!ended"
              @click="closeChat"
            >
              <CloseCircleIcon class="icon"></CloseCircleIcon>
              <span>{{ t('actions.endChat') }}</span>
            </div>
            <div
              class="item text-success"
              :class="{ disabled: loadingStates.create }"
              v-if="ended"
              @click="createChat"
            >
              <ChatIcon class="icon"> </ChatIcon>
              <span>{{ t('actions.newChat') }}</span>
            </div>
          </template>
        </VDropdown>
        <VButton
          class="button-text button-round action justify-center"
          @click="() => toggleWidget()"
        >
          <ChevronDownIcon></ChevronDownIcon>
        </VButton>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { useChatWidgetStore } from '@/stores/chatWidget'
import { useChatsStore } from '@/stores/chats'
import { useLocalStorage } from '@/composables/storage'
import { storeToRefs } from 'pinia'
import { computed, reactive, ref } from 'vue'

import CloseCircleIcon from './svg/CloseCircleIcon.svg'
import ChatIcon from './svg/ChatIcon.svg'
import MenuIcon from './svg/DotsHorizontalIcon.svg'
import LeaveIcon from './svg/LeaveIcon.svg'
import ChevronDownIcon from './svg/ChevronDownIcon.svg'
import VDropdown from './form/VDropdown.vue'
import VButton from './form/VButton.vue'
import VAvatar from './form/VAvatar.vue'
import { type ContactInformation } from '@/api/chats'
import { usePrivacyName } from '@/composables/users'
import { useI18n } from '@/composables/i18n'
import { useApi } from '@contactoneteam/c1webapi'

import IgniteLogo from '@/assets/image/band/bandbet.png'

const { t } = useI18n()
const chatWidgetStore = useChatWidgetStore()
const chatStore = useChatsStore()
const { from: bFrom, ended } = storeToRefs(chatStore)
const { state } = storeToRefs(chatWidgetStore)
const api = useApi()
const contactSession = useLocalStorage('co')
const chatSession = useLocalStorage('ch')

const menu = ref<InstanceType<typeof VDropdown>>()

const loadingStates = reactive({
  close: false,
  create: false
})

const logos: { [key: string]: unknown } = {
  'ignite': IgniteLogo
}

const privacyName = usePrivacyName()

const from = computed<ContactInformation>(() => {
  const defaultLabel = t.value('messages.robot')
  const logo = logos[api.client?.name || 'default'] as string

  if (!bFrom.value.length)
    return {
      id: 'system',
      name: api.client?.persona?.name || defaultLabel,
      photoUrl: logo || null,
      type: 'user',
      uid: 'system'
    }

  return bFrom.value[0]
})

const isIdentification = computed(() => state.value.meta.chat.state === 'form')
const isChat = computed(() => ['chat', 'end'].includes(state.value.meta.chat.state))

let loggedOut = ref(false)
function logout() {
  chatSession.value = ''
  contactSession.value = ''

  loggedOut.value = true
}

async function createChat() {
  if (loadingStates.create) return

  loadingStates.create = true

  try {
    await chatStore.createChat()
  } catch (error: any) {
    console.error('cant create chat', error)
  } finally {
    loadingStates.create = false
    menu.value?.toggle()
  }
}

function toggleWidget() {
  chatWidgetStore.toggleButtonState()
}

async function closeChat() {
  if (loadingStates.close) return
  loadingStates.close = true

  try {
    await chatStore.closeChat()
  } catch (error: any) {
    console.log('cant close chat')
  } finally {
    loadingStates.close = false
    menu.value?.toggle()
  }
}
</script>
