<template>
  <div class="v-dropdown" v-bind="{ id }" ref="dropdownWrapperEl">
    <slot v-bind="{ toggle }"> </slot>

    <Transition name="fade">
      <div class="dropdown-content" :class="{ toggled: active }" v-if="active">
        <slot name="items"></slot>
      </div>
    </Transition>
  </div>
</template>

<script setup lang="ts">
import { onBeforeUnmount, onMounted, ref } from 'vue'
import { getDomElement } from '../ChatWidget'

const id = crypto.randomUUID()

const dropdownWrapperEl = ref<HTMLDivElement | null>(null)
const active = ref(false)

function toggle() {
  active.value = !active.value
}

function onClick(event: Event) {
  if (!dropdownWrapperEl.value?.contains(event.target as Node) && active.value) toggle()
}

onMounted(() => {
  const shadow = getDomElement()

  if (shadow) {
    shadow.addEventListener('click', onClick)
  }
})

onBeforeUnmount(() => {
  getDomElement()?.removeEventListener('click', onClick)
})

defineExpose({
  toggle
})
</script>
