<template>
  <img class="message-image" :src="message?.content.url || ''" v-if="image" />
  <video controls v-if="message && video" class="message-video">
    <source :src="message.content.url || ''" :type="message.content.type" />
    Seu navegador não suporta vídeos.
  </video>
  <div v-if="message?.content.text" class="text">
    {{ message.content.text }}
  </div>
</template>

<script setup lang="ts">
import { useChatsStore } from '@/stores/chats'
import { storeToRefs } from 'pinia'
import { computed, toRefs } from 'vue'

const props = defineProps<{
  messageId: string
}>()
const { messageId } = toRefs(props)

const chatStore = useChatsStore()
const { getMessageById } = storeToRefs(chatStore)

const message = computed(() => {
  return getMessageById.value(messageId.value)
})

const contentType = computed(() => {
  return message.value?.content.type
})

const image = computed(() => {
  return contentType.value?.includes('image')
})

const video = computed(() => {
  return contentType.value?.includes('video')
})
</script>
