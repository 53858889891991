import { computed } from 'vue'

type MaybeUser = Partial<{
  name: string
  editedName: string
}>
export function usePrivacyName() {
  return computed(() => (user: MaybeUser) => {
    const [name] = (user.editedName || user.name || 'Usuário').split(' ')

    return name
  })
}
