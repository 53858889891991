<template>
  <div class="attachments-dock col" style="gap: 5px" v-if="temporaryAttachments.visible">
    <small class="text-muted" style="font-weight: 570">Máximo de anexos por mensagem: 5</small>
    <small v-if="notice"
      >Os anexos destacados em <span class="invalid-red">vermelho</span> possuem tipos não
      permitidos e <strong>não serão enviados</strong> junto da mensagem.</small
    >
    <div class="row flex-wrap" style="gap: 5px">
      <FileChip
        @remove="() => removeAttachment(attach)"
        v-for="(attach, idx) of temporaryAttachments.items"
        v-bind="{ name: attach.name, type: attach.type }"
        :key="`attachment-${idx}`"
        removable
      ></FileChip>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { type LocalAttachment, useChatsStore } from '@/stores/chats'
import { storeToRefs } from 'pinia'
import { useIllegalFiles } from './files'

import FileChip from './FileChip.vue'

const chatsStore = useChatsStore()
const { temporaryAttachments } = storeToRefs(chatsStore)

const notice = computed(() => {
  return temporaryAttachments.value.items.some((att) => isIllegal.value(att.type))
})

const isIllegal = useIllegalFiles()

function removeAttachment(item: LocalAttachment) {
  const removed = temporaryAttachments.value.items.splice(
    temporaryAttachments.value.items.indexOf(item),
    1
  )

  // hide on empty
  if (!temporaryAttachments.value.items.length && temporaryAttachments.value.visible) {
    temporaryAttachments.value.visible = false
  }

  return removed
}
</script>
