<template>
  <input v-bind="{ type }" class="v-input" v-model="text" />
</template>

<script setup lang="ts">
import { type InputTypeHTMLAttribute, computed } from 'vue'

const props = withDefaults(
  defineProps<{
    modelValue: string
    type?: InputTypeHTMLAttribute
  }>(),
  {
    type: 'text'
  }
)
const emit = defineEmits<{
  (e: 'update:modelValue', v: string): void
  (e: 'change', to: string, from: string): void
}>()

const text = computed({
  get() {
    return props.modelValue
  },
  set(t) {
    const from = props.modelValue
    emit('update:modelValue', t)
    emit('change', t, from)
  }
})
</script>
