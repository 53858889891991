<template>
  <div class="b-recipe">
    <VImg
      height="280px"
      width="100%"
      :src="data.imageUrl"
      class="relative recipe-image"
      style="background-size: 100% 100%; background-repeat: no-repeat"
    >
      <VButton class="button-round button-text action-prev" @click="prev">
        <ChevronDotLeftIcon></ChevronDotLeftIcon>
      </VButton>
      <VButton class="button-round button-text action-next" @click="next">
        <ChevronDotRightIcon></ChevronDotRightIcon>
      </VButton>
    </VImg>
    <div class="title" :title="data.name">
      {{ data.name }}
    </div>
    <div class="stats">
      <div class="stat">
        <span>
          <ThinkingIcon class="icon"></ThinkingIcon>
          Dificuldade</span
        >
        <span>{{ data.difficulty }}</span>
      </div>

      <div class="stat">
        <span>
          <TimerIcon class="icon" />
          Tempo de Preparo</span
        >
        <span>{{ data.preparationTime }}</span>
      </div>

      <div class="stat">
        <span>
          <StarIcon class="icon" />
          Programa
        </span>
        <span>{{ data.show }}</span>
      </div>
    </div>
    <div class="button">
      <VButton class="choose" @click.stop="choose"> {{ label }} </VButton>
    </div>
    <div class="share">
      <span>Compartilhar</span>
      <div class="items">
        <WhatsAppIcon
          style="color: #25d366"
          class="icon cursor-pointer"
          @click="() => share('whatsapp')"
        ></WhatsAppIcon>
        <!-- <FacebookIcon style="color: #4267B2" class="icon cursor-pointer" @click="() => share('facebook')"></FacebookIcon> -->
        <EmailIcon class="icon cursor-pointer" @click="() => share('email')"></EmailIcon>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import { pick } from '@/composables/helpers'
import { useChatsStore } from '@/stores/chats'

import ChevronDotLeftIcon from '@/components/svg/ChevronDotLeftIcon.svg'
import ChevronDotRightIcon from '@/components/svg/ChevronDotRightIcon.svg'
import EmailIcon from '@/components/svg/EmailIcon.svg'
// import FacebookIcon from '@/components/svg/FacebookIcon.svg'
import WhatsAppIcon from '@/components/svg/WhatsAppIcon.svg'
import ThinkingIcon from '@/components/svg/ThinkingIcon.svg'
import StarIcon from '@/components/svg/StarIcon.svg'
import TimerIcon from '@/components/svg/TimerIcon.svg'

import VImg from '../VImg.vue'
import VButton from '../form/VButton.vue'

const emit = defineEmits<{
  (e: 'next'): void
  (e: 'prev'): void
}>()
const props = defineProps<{
  data: {
    id: string
    url: string
    imageUrl: string
    chef: string
    name: string
    show: string
    difficulty: string
    preparationTime: string
  }
  ogMessageId: string
}>()

const chatStore = useChatsStore()

const chooseTexts = ['Gostei dessa!', 'Quero essa!', 'Manda essa!']

const label = ref<string | null>(null)

function next() {
  emit('next')
}

function prev() {
  emit('prev')
}

function share(sm: 'facebook' | 'whatsapp' | 'email') {
  const text = `Olha essa receita que encontrei no Band Receitas ${props.data.url}`

  if (sm === 'email') {
    window.open(`mailto:?body=${text}`, '_self')
  } else if (sm === 'whatsapp') {
    window.open(`https://api.whatsapp.com/send?text=${text}`, '_blank')?.focus()
  }
}

function pickLabel() {
  label.value = pick(chooseTexts)
}

// chosing a recipe is the same as clicking an interactive button/option
// so we need to handle it like so
async function choose() {
  const { name, id } = props.data

  await chatStore.sendMessage({
    id: '',
    content: {
      text: id,
      type: 'text/plain',
      url: null
    },
    replyMessageId: props.ogMessageId,
    sentAt: new Date().toISOString(),
    customData: {
      buttons: [{ label: name, value: props.data.id }],
      contextData: null,
      options: null
    }
  })
}

pickLabel()

watch(
  () => props.data,
  () => {
    pickLabel()
  },
  { deep: true }
)
</script>
