import { helpers } from '@vuelidate/validators'

export const atLeastTwoNames = helpers.withMessage(
  'Deve conter ao menos dois nomes',
  (value: string) => {
    return value.split(' ').length >= 2
  }
)

export const numbersNotAllowed = helpers.withMessage(
  'Não pode conter números',
  (value: string) => {
    return !/\d/.test(value)
  }
)

export const required = helpers.withMessage(
  'Campo obrigatório',
  (value: string) => {
    return value?.trim().length > 0
  }
)

export const cpfSize = helpers.withMessage(
  'O CPF está incompleto',
  (value: string) => {
    return value.length === 14
  }
)

export const validateCpf = helpers.withMessage(
  'O CPF é inválido',
  (cpf: string) => {
    if (!cpf) return false

    cpf = cpf.replace(/[^\d]+/g, '')

    if (cpf.length !== 11) return false

    if (
      cpf === '00000000000' ||
      cpf === '11111111111' ||
      cpf === '22222222222' ||
      cpf === '33333333333' ||
      cpf === '44444444444' ||
      cpf === '55555555555' ||
      cpf === '66666666666' ||
      cpf === '77777777777' ||
      cpf === '88888888888' ||
      cpf === '99999999999'
    ) return false

    let add = 0
    for (let i = 0; i < 9; i++) add += parseInt(cpf.charAt(i)) * (10 - i)
    let rev = 11 - (add % 11)
    if (rev === 10 || rev === 11) rev = 0
    if (rev !== parseInt(cpf.charAt(9))) return false

    add = 0
    for (let i = 0; i < 10; i++) add += parseInt(cpf.charAt(i)) * (11 - i)
    rev = 11 - (add % 11)
    if (rev === 10 || rev === 11) rev = 0
    if (rev !== parseInt(cpf.charAt(10))) return false

    return true
  }
)