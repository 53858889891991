import { computed } from 'vue'

export const explicitRejectTypes = [
  'msdos',
  'exe',
  'binary',
  'msdownload',
  'application/octet-stream',
  'application/binary',
  'html',
  'css',
  'script',
  'multipart'
]
export function useIllegalFiles() {
  return computed(() => (type: string) => {
    return explicitRejectTypes.some((mime) => {
      return type.includes(mime)
    })
  })
}
