<template>
  <section
    :id="toplevelId"
    class="v-chat-widget"
    :class="[
      `theme-${theme}`,
      `state-${state.view}`,
      `button-${state.meta.button ? 'active' : 'inactive'}`,
      `x-${_x} y-${_y}`,
      `lnf-${api.client?.id}`,
      `theme-${hideButton ? 'no-button' : 'button'}`
    ]"
    :style="{
      'z-index': props.zIndex
    }"
  >
    <Transition name="fade">
      <ChatWindow v-if="state.meta.button" />
    </Transition>
    <Transition name="fade">
      <ChatButton v-if="state.view === 'button'" />
    </Transition>
  </section>
</template>

<script setup lang="ts">
import { Volt } from '@/api/Volt'
import type { HorizPosition, VertPosition, ThemeOption } from '@/types'
import { getClientById } from '@/api/clients'
import { toRefs, watch, onBeforeMount, onMounted, onBeforeUnmount, computed } from 'vue'
import { useChatWidgetStore } from '@/stores/chatWidget'
import { useContactsStore } from '@/stores/contacts'
import { useApi } from '@contactoneteam/c1webapi'
import { storeToRefs } from 'pinia'

import ChatButton from './ChatButton.vue'
import ChatWindow from './ChatWindow.vue'
import { Locale, useI18n } from '@/composables/i18n'

const api = useApi()
const contactStore = useContactsStore()
const { softAuthenticated } = storeToRefs(contactStore)
const chatWidgetStore = useChatWidgetStore()
const { state } = storeToRefs(chatWidgetStore)
const { locale } = useI18n()

const toplevelId = 'chat_toplevel'

class ClientReferenceError extends Error {
  constructor(message: string) {
    super(message)

    this.name = 'ClientReferenceError'
  }
}

const props = withDefaults(
  defineProps<{
    id: string
    theme?: ThemeOption
    x?: HorizPosition
    y?: VertPosition
    lang?: Locale
    zIndex?: number
    hideButton?: boolean
  }>(),
  {
    hideButton: false,
    zIndex: 200,
    theme: 'default'
  }
)
const { id: client, lang } = toRefs(props)

const _x = computed(() => {
  if (props.x) {
    return props.x
  }

  if (api.client?.x) {
    return api.client.x
  }

  return 'right'
})

const _y = computed(() => {
  if (props.y) {
    return props.y
  }

  if (api.client?.y) {
    return api.client.y
  }

  return 'bottom'
})

async function applyClient(id: string) {
  const client = getClientById(id)

  if (!client)
    throw new ClientReferenceError(`Cannot load definition labeled by ${id}: it does not exist (1)`)

  await api.useClient(client)

  Object.assign(Volt.defaults, client.modules.volt)
}

function getHost() {
  return document.querySelector('c1-chat-widget')
}

function getTopLevelElement() {
  return getHost()?.shadowRoot?.querySelector(`#${toplevelId}`) || null
}

function injectCss(url: string) {
  const el = document.createElement('link')

  el.setAttribute('rel', 'stylesheet')
  el.setAttribute('href', url)

  getHost()?.shadowRoot?.appendChild(el)
}

onBeforeMount(async () => {
  await applyClient(client.value)
})

onBeforeUnmount(() => {
  contactStore.clearRefreshes()
})

onMounted(() => {
  if (api.client?.locale && !lang.value?.length) {
    locale.value = api.client.locale as Locale
  } else if (lang.value?.length) {
    locale.value = lang.value
  }

  window.$volt = {
    show: chatWidgetStore.show,
    hide: chatWidgetStore.hide,
    toggle: chatWidgetStore.toggleButtonState,
    
    conversationOngoing: () => {
      return softAuthenticated.value ? softAuthenticated.value : false
    },
    version() {
      return '0.9.0'
    }
  }

  if (window.$volt_ready) {
    window.$volt_ready(window.$volt)
  }
})

watch(client, async (is, was) => {
  if (is !== was) {
    await applyClient(is)
  }
})

watch(lang, (is) => {
  if (is && is.length) {
    locale.value = is
  }
})
</script>

<style lang="scss">
@import '@/assets/scss/main.scss';
@import '@/assets/scss/chat/main.scss';
@import '@/assets/scss/chat/extensions/main.scss';
</style>
