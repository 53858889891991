import { acceptHMRUpdate, defineStore, storeToRefs } from 'pinia'
import { runApi } from '@contactoneteam/c1webapi'
import type { UpdateOperation, UpdatesQuery } from '@/api/updates'
import { useChatsStore } from './chats'
import { useContactsStore } from './contacts'
import { Volt } from '@/api/Volt'

type LastSetInformation = {
  ranAt: string | null
  items: UpdateOperation[] | null
}

export const useUpdatesStore = defineStore('updates', {
  state() {
    return {
      lastSet: {
        ranAt: null,
        items: null
      } as LastSetInformation
    }
  },
  getters: {},
  actions: {
    parseOperations(updates: UpdateOperation[]) {
      for (const updatesCloseOp of updates.filter((u) => u.operationName === 'CloseChat')) {
        const updatesGetOp = updates.findIndex(
          (op) => op.operationName === 'GetChat' && op.operationKey === updatesCloseOp.operationKey
        )

        if (updatesGetOp !== -1) {
          console.error(
            'Received both Get and CloseChat operations from GetUpdates. GetChat was ignored.'
          )
          updates.splice(updatesGetOp, 1)
        }
      }

      return updates
    },
    async getUpdates(query: UpdatesQuery = { contactId: '' }) {
      if (!query.contactId?.length) {
        const contactStore = useContactsStore()
        if (!contactStore.contact) throw new Error('cant handle updates without a contact')

        query.contactId = contactStore.contact.id
      }

      const response = await runApi(Volt.GetUpdates, {
        data: query
      })

      if (response.ok) {
        const { data: updates } = response

        for await (const operation of this.parseOperations(updates)) {
          await this.dispatchOperation(operation)
        }

        this.lastSet.ranAt = new Date().toISOString()
        this.lastSet.items = updates
      }
    },
    invalidOperation(name: string, reason: string) {
      console.log(`GetUpdates operation %s won't have effects: %s`, name, reason)
    },
    async dispatchOperation(operation: UpdateOperation) {
      const { operationName: name, operationKey: value } = operation

      const chatsStore = useChatsStore()

      if (name === 'CloseChat') {
        if (value) {
          await chatsStore.getChat()
        } else {
          this.invalidOperation('CloseChat', 'no key')
        }
      } else if (name === 'GetChat') {
        if (value) {
          await chatsStore.getChat()
        } else {
          this.invalidOperation('GetChat', 'no key')
        }
      } else if (name === 'GetChats') {
        await chatsStore.getChat()
      } else if (name === 'GetChatMessages') {
        if (value) {
          chatsStore.getChatMessages()
        } else {
          this.invalidOperation('GetChatMessages', 'no key')
        }
      } else {
        throw new ReferenceError(`No handler for operationKey ${name} on updatesStore`)
      }
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUpdatesStore, import.meta.hot))
}
