<template>
  <small class="text-sucess queue-notice row items-center">
    <VSpinner style="margin-right: 5px; height: 12px; width: 12px"></VSpinner>
    {{ t('messages.queue') }}
  </small>
</template>

<script setup lang="ts">
import { useI18n } from '@/composables/i18n'
import VSpinner from './form/VSpinner.vue'

const { t } = useI18n()
</script>
